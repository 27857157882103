.filepond--panel-root {
  border-radius: 0.5em;
  border-color: #660e7a;
}
.filepond--item-panel {
  background-color: #112233;

}
::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;
}
.MUIDataTable-responsiveScroll-* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.MUIDataTable-responsiveScroll-*::-webkit-scrollbar {
  display: none;
}